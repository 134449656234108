import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/seo'
import Title from '../components/title'
import Header from '../components/header'
import Container from '../components/container'
import FeatureBlock from '../components/feature-block'
import Layout from '../layout'
import Image from '../components/image'
import AnimatedArticle from '../components/animated-article'
import AnimatedAside from '../components/animated-aside'
import P from '../components/paragraph'
import styled from 'styled-components'
import { fluidRange } from 'polished'

const GreyBox = styled.div`
  max-width: 650px;
  background: ${({ theme }) => theme.color.grey.light};
  ${fluidRange({
      prop: 'padding-top',
      fromSize: '20px',
      toSize: '70px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-bottom',
      fromSize: '60px',
      toSize: '120px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-left',
      fromSize: '20px',
      toSize: '100px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'padding-right',
      fromSize: '20px',
      toSize: '100px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'font-size',
      fromSize: '1.1em',
      toSize: '1.2em'
    },
    '300px',
    '1600px'
  )}
`

const BespokePage = () => {
  const { nod, horatio, nod2 } = useStaticQuery(graphql`
    query {
      nod: file(relativePath: { eq: "bespoke/nod.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 740, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      horatio: file(relativePath: {
        eq: "bespoke/horatio-london-packaging.png"
      }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nod2: file(relativePath: { eq: "bespoke/nod2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) { ...GatsbyImageSharpFluid_withWebp }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Bespoke'
        description={`
          From product creation through to packaging, marketing and launch,
          Valorem Distribution offer a completely bespoke service to help bring
          niche perfume brands to global markets.
        `}
      />

      <Header />

      <Container wide noPadding='top'>
        <FeatureBlock center>
          <AnimatedArticle onLoad>
            <Title fancy>Bespoke</Title>
            <P noMargin='bottom'>
              Valorem has an obsession for creativity – not only a desire to
              engage existing brands, but to assist in establishing new ones.
              From product creation, packaging, marketing, communications and on
              to launch. Valorem has a finite understanding of what it is to be
              different; how to make this a commercial advantage.
            </P>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={nod.childImageSharp.fluid}
              notch='topLeftSmall'
              alt='Bespoke Shipping Services'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container noPadding>
        <GreyBox>
          <P>
            In committing to the brand building experience and by breaking away
            from the expected, Valorem’s uniquely experienced and knowledgeable
            team can offer your brand as much support as you require, smoothing
            routes to global markets, assuring success.
          </P>
          <P>
            Not much thinking outside the box as thinking beyond the way others
            think.
          </P>
          <P>
            We believe that creating mutually respectful partnerships s the key
            to building a fruitful future together.  We are always on the look
            out for imaginative, ambitious brands that break the beauty mould.
          </P>
        </GreyBox>
        <Image
          fluid={nod2.childImageSharp.fluid}
          notch='bottomLeft'
          alt='Nod perfume bottles'
          css={`
            max-width: 600px;
            margin: -80px 40px 0 auto;
            @media(max-width: ${({ theme }) => theme.breakpoint.large}) {
              margin-top: -50px;
              margin-bottom: 50px;
            }
          `}
        />
      </Container>

      <Container wide noPadding='top'>
        <FeatureBlock center grey={false}>
          <AnimatedAside right>
            <Image
              fluid={horatio.childImageSharp.fluid}
              notch='topRightSmall'
              alt='Horatio perfume bottle and packaging'
            />
          </AnimatedAside>
          <AnimatedArticle>
            <Title fancy>Experience</Title>
            <P>
              Valorem sets the gold standard in finely tuned distribution
              services, specialising in the representation and distribution of
              high end brands that celebrate beauty and radiate ambition.
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>
    </Layout>
  )
}

export default BespokePage
